<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
    >
      <template #content>
        <div class="pa-4">
          <v-text-field
            hide-details
            label="Nome do perfil"
            v-model="form.name"
          ></v-text-field>
        </div>

        <h3 class="pa-4 mt-2">Permissões do perfil</h3>

        <div class="pa-4 mt-2">
          <v-checkbox
            label="Selecionar/Desmarcar tudo"
            :value="true"
            :false-value="false"
            v-model="selectAllData"
          ></v-checkbox>
        </div>

        <v-expansion-panels class="pa-4" v-model="panel">
          <v-expansion-panel
            :key="permissionGroup.id"
            v-for="permissionGroup in permissionGroups"
          >
            <v-expansion-panel-header>
              {{ permissionGroup.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item-group multiple>
                <v-list-item
                  :key="permission.id"
                  v-for="permission in permissionGroup.Permissions"
                >
                  <v-list-item-action>
                    <v-checkbox
                      v-model="form.selectedPermissions"
                      :value="permission.id"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      permission.permissionName
                    }}</v-list-item-title>
                    <!-- <v-list-item-subtitle>Notify me about updates to apps or games that I downloaded</v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template #actions></template>
    </BaseForm>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog">Fechar</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    loadingPermissions: false,
    form: { name: null, selectedPermissions: [] },
    panel: 0,
    successDialog: false,
    permissionGroups: [],
    actionAfterOperation: null,
    selectAllData: false,
  }),
  computed: {},
  watch: {
    selectAllData(newValue) {
      this.selectAll(newValue);
    },
  },
  methods: {
    selectAll(value) {
      if (value) {
        this.form.selectedPermissions = [];
        for (const group of this.permissionGroups) {
          for (const permission of group.Permissions) {
            this.form.selectedPermissions.push(permission.id);
          }
        }
      } else {
        this.form.selectedPermissions = [];
      }
    },

    validate() {
      const errors = [];

      if (!this.form.name) errors.push("Informe o nome do perfil de usuário");

      if (!this.form.selectedPermissions.length)
        errors.push(
          "Selecione pelo menos uma permissão para o perfil de usuário"
        );

      return errors;
    },

    save(actionAfterOperation) {
      // console.log(this.form);

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        await this.$axios.post(`/users/access-profiles`, this.form);

        this.clearForm();

        this.successDialog = true;
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(
          `/users/access-profiles/${this.$route.params.id}`,
          this.form
        );

        this.successDialog = true;
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async loadPermissions() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(`/permissions`);

        this.setPermissions(response.data);
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },
    setPermissions(data) {
      this.permissionGroups = [...data];
    },
    async loadProfileData() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(
          `/users/access-profiles/${this.$route.params.id}`
        );

        this.setProfileData(response.data);
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },

    setProfileData(data) {
      const cpData = { ...data };

      const { Permissions: permissions, name } = cpData;

      this.form.selectedPermissions = permissions.map((p) => p.id);
      this.form.name = name;
    },

    closeSuccessDialog() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
      else if (this.actionAfterOperation === "stay") this.successDialog = false;
    },
    clearForm() {
      this.form = { selectedPermissions: [] };
    },
  },
  created() {
    this.loadPermissions();
    if (this.mod === "update") this.loadProfileData();
    // console.log(this.mod);
    // console.log(this.$route.params.id);
  },
};
</script>

<style>
</style>